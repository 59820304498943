import { getFromIDB, setInIDB } from '../persistence/indexeddb';
import { Metrics } from '../metrics';
import { toNamespacedKey } from '../utils/toNamespacedKey';

// Rounds with varying precision to produce 10 buckets per order of magnitude
// E.g 0-100kb gets bucketed into 10, 20, 30, etc
// 100 - 6000kb gets bucketed into 100, 200, 300, etc
// This gives us decent resolution on response sizes — we'll get 70 buckets of data.
const bucketTotalSize = sizeBytes => {
  const sizeKB = Math.round(sizeBytes / 1024);
  const factor = sizeKB < 100 ? 10 : 100;
  return `${Math.min(Math.ceil(sizeKB / factor) * factor, 1024 * 6)}`;
};
export const trackDataChanges = ({
  isCacheMiss,
  namespace,
  entityName,
  segment,
  cacheKey,
  resolvePortalAndUserId,
  totalSizeBytes,
  patchSizeBytes,
  patchSizePct
}) => {
  const key = toNamespacedKey(namespace, entityName, cacheKey);
  const responseSizeBucket = bucketTotalSize(totalSizeBytes);
  if (isCacheMiss) {
    Metrics.counter('cold-cache', Object.assign({
      entityNamespace: namespace,
      entityName
    }, segment ? {
      segment
    } : {}, {
      responseSizeBucket
    })).increment();

    // Initialize duplicate request count to 0
    return setInIDB({
      key,
      value: 0,
      store: 'metrics',
      resolvePortalAndUserId
    });
  } else {
    Metrics.counter('warm-cache', Object.assign({
      entityNamespace: namespace,
      entityName
    }, segment ? {
      segment
    } : {}, {
      responseSizeBucket
    })).increment();
    return getFromIDB({
      key,
      store: 'metrics',
      resolvePortalAndUserId
    }).catch(() => 0).then(duplicateRequestCount => {
      if (patchSizeBytes === 0) {
        // Increment duplicate request counter by one if the patch size is 0
        // (i.e no change between cache and network)
        return setInIDB({
          key,
          value: duplicateRequestCount + 1,
          store: 'metrics',
          resolvePortalAndUserId
        });
      } else {
        // Report the count of identical requests that we reached
        Metrics.histogram('duplicate-request-count', Object.assign({
          entityNamespace: namespace,
          entityName
        }, segment ? {
          segment
        } : {}, {
          responseSizeBucket
        })).update(duplicateRequestCount);

        // Report the size of the response (after normalization, in bytes)
        Metrics.histogram('total-size', Object.assign({
          entityNamespace: namespace,
          entityName
        }, segment ? {
          segment
        } : {}, {
          responseSizeBucket
        })).update(totalSizeBytes);

        // Report the size of the hypothetical json-patch operations (in bytes)
        Metrics.histogram('patch-size', Object.assign({
          entityNamespace: namespace,
          entityName
        }, segment ? {
          segment
        } : {}, {
          responseSizeBucket
        })).update(patchSizeBytes);

        // Report the percentage size of the patch as compared to the normalized data
        // (i.e how efficient would this patch have been)
        Metrics.histogram('patch-pct', Object.assign({
          entityNamespace: namespace,
          entityName
        }, segment ? {
          segment
        } : {}, {
          responseSizeBucket
        })).update(patchSizePct);

        // Reset our count to 0
        return setInIDB({
          key,
          value: 0,
          store: 'metrics',
          resolvePortalAndUserId
        });
      }
    });
  }
};