import { getFromIDB, removeAllKeysWithPrefix, removeFromIDB, setInIDB } from '../persistence/indexeddb';
import { Metrics } from '../metrics';
import { createInMemoryCache } from './createInMemoryCache';
import { withQuickLoad } from '../withQuickLoad';
import { toNamespacedKey } from '../utils/toNamespacedKey';
import { freeze } from './freeze';
import { calculateMetrics } from '../worker/calculateMetrics';
import { defaultResolvePortalAndUserId } from '../utils/defaultResolvePortalAndUserId';

// Default to 1 day of staleness tolerance
export const DEFAULT_STALENESS_CUTOFF = 1000 * 60 * 60 * 24;
export function createPersistedCache({
  namespace,
  entityName,
  stalenessCutoff = DEFAULT_STALENESS_CUTOFF,
  allowEagerCacheReturn: topLevelEagerConfig = false,
  deepFreeze = false,
  resolvePortalAndUserId = defaultResolvePortalAndUserId,
  metricsConfig
}) {
  const __cache = createInMemoryCache({
    cacheName: toNamespacedKey(namespace, entityName)
  });
  return Object.assign({}, __cache, {
    clear: () => {
      __cache.clear();
      removeAllKeysWithPrefix({
        prefix: toNamespacedKey(namespace, entityName),
        resolvePortalAndUserId
      }).catch(() => {
        // Do nothing
      });
      return true;
    },
    delete: cacheKey => {
      __cache.delete(cacheKey);
      removeFromIDB({
        key: toNamespacedKey(namespace, entityName, cacheKey),
        resolvePortalAndUserId
      }).catch(() => {
        // Do nothing
      });
      return true;
    },
    set: (cacheKey, value) => {
      __cache.set(cacheKey, value.then(result => deepFreeze ? freeze(result) : result));
      value.then(result => setInIDB({
        key: toNamespacedKey(namespace, entityName, cacheKey),
        value: {
          data: result,
          storedAt: Date.now()
        },
        resolvePortalAndUserId
      })).catch(() => {
        // Do nothing
      });
    },
    readThrough: ({
      cacheKey,
      fetchValue,
      opts
    }) => __cache.readThrough({
      cacheKey,
      opts,
      fetchValue: () => {
        var _metricsConfig$conver;
        let fetchFinished = false;
        const metricsDimension = (metricsConfig === null || metricsConfig === void 0 || (_metricsConfig$conver = metricsConfig.convertKeyToMetricsDimension) === null || _metricsConfig$conver === void 0 ? void 0 : _metricsConfig$conver.call(metricsConfig, cacheKey)) || null;

        // Fetch the data, marking when it finishes to disable the early cache return
        const fetchPromise = fetchValue().then(result => {
          fetchFinished = true;
          return result;
        });

        // Kick off a read from IndexedDB for the data. Rejects if the data isn't present.
        // Will automatically consume a quickLoaded request if one is present for this key.
        const cacheLoadPromise = withQuickLoad({
          namespace,
          entityName,
          cacheKey,
          baseLoad: () => getFromIDB({
            key: toNamespacedKey(namespace, entityName, cacheKey),
            resolvePortalAndUserId
          })
        });
        calculateMetrics({
          fetchPromise,
          // Pass the cache result *without* staleness check. That check ensures we don't show
          // data too out-of-date for early cache returns or fault tolerance, but we don't care
          // about it for metrics collection.
          cacheLoadPromise,
          namespace,
          entityName,
          cacheKey,
          segment: metricsDimension,
          metricsConfig,
          resolvePortalAndUserId
        }).catch(() => {
          // Do nothing — we don't care if this errors out.
        });

        // Wait for both fetch and cache to settle, then save the fetch result in IndexedDB
        // We want to set the value in IDB regardless of whether the cache load succeeded or not,
        // but we do need to wait for the fetch to succeed.
        cacheLoadPromise.finally(() => {
          fetchPromise.then(result =>
          // Overwrite any currently-persisted data with the result (but don't wait on it)
          setInIDB({
            key: toNamespacedKey(namespace, entityName, cacheKey),
            value: {
              data: result,
              storedAt: Date.now()
            },
            resolvePortalAndUserId
          })).catch(() => {
            // Do nothing!
          });
        }).catch(() => {
          // Do nothing!
        });
        const cacheLoadWithStalenessCheck = cacheLoadPromise.then(({
          data,
          storedAt
        }) => {
          // Ensures we only return cached data for a certain amount of time, to prevent things
          // from getting permanently stuck
          if (storedAt + stalenessCutoff > Date.now()) {
            return data;
          }

          // Throwing here makes error handling in the combined promises smoother.
          throw Error('Result was stale');
        });

        // If either promise rejects, we want to fall back to the other. To prevent circular dependency
        // issues, we orchestrate this by "enhancing" each promise with the expected error handling behavior
        // as separate calls from the initial setup.
        // If the fetch promise rejects, fall back on the indexeddb promise.
        const enhancedFetchPromise = fetchPromise.catch(fetchError => cacheLoadWithStalenessCheck.then(result => {
          Metrics.counter('fault-tolerant-cache-used', Object.assign({
            entityNamespace: namespace,
            entityName
          }, metricsDimension && {
            segment: metricsDimension
          })).increment();
          return result;
        }).catch(() => {
          // If the indexeddb promise rejects, surface the load error instead. This presents
          // an actionable/useful error to the caller, rather than surfacing "random issue with indexeddb".
          // This is a terminal state — both promises have rejected, so we cannot satisfy the request.
          throw fetchError;
        }));
        const enhancedIndexedDBReadPromise = cacheLoadWithStalenessCheck
        // If IndexedDB wins the race, we need to do an additional check to ensure that
        // this cache is willing to tolerate some staleness (and that the fetch didn't return first)
        .then(result => {
          if ((topLevelEagerConfig || opts !== null && opts !== void 0 && opts.allowEagerCacheReturn) && !fetchFinished) {
            Metrics.counter('eager-cache-used', Object.assign({
              entityNamespace: namespace,
              entityName
            }, metricsDimension && {
              segment: metricsDimension
            })).increment();
            return result;
          }
          return fetchPromise;
        }).catch(
        // If the indexeddb promise rejects, fall back on the fetch promise. If the fetch promise rejects,
        // we have reached a terminal state — both promises have rejected, so we cannot satisfy the request.
        () => fetchPromise);

        // Race for either "enhanced" promise to complete. Both have the correct error handling built in, so we
        // don't care which settles first — we simply want to settle when either settles.
        return Promise.race([enhancedFetchPromise, enhancedIndexedDBReadPromise]).then(result => deepFreeze ? freeze(result) : result);
      }
    })
  });
}